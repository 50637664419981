/**
 * Created by yongzhang9 on 2016/5/12.
 */

$(function () {

    //.ajaxError事件定位到document对象，文档内所有元素发生ajax请求异常，都将冒泡到document对象的ajaxError事件执行处理
    $(document).ajaxError(
        //所有ajax请求异常的统一处理函数，处理
        function (event, xhr, options, exc) {
            if (xhr.status == 'undefined') {
                return;
            }
            switch (xhr.status) {
                case 403:
                    // 未授权异常
                    if(mDialog) mDialog("系统拒绝：您没有访问权限。");
                    else layer.msg("系统拒绝：您没有访问权限。");
                    break;
                case 404:
                    if(mDialog) mDialog("您访问的资源不存在。");
                    else layer.msg("您访问的资源不存在。");
                    break;
                case 500:
                    if(mDialog) mDialog("服务异常，请稍后再试...");
                    else layer.msg("服务异常，请稍后再试...");
                    break;
                default:
                    if(mDialog) mDialog("未知异常，请稍后再试...");
                    else layer.msg("未知异常，请稍后再试...");
            }
        }
    );
});
//来源 用于埋点上报
var originFrom;
var customRefFromCookie;
//获取来源
function getOriginSource() {
    if(originFrom){
        return originFrom;
    }
    customRefFromCookie = getCookie('customRef',true);
    if(customRefFromCookie){
        customRefFromCookie = eval('(' + customRefFromCookie + ')');
        if(customRefFromCookie.type == 'ucenter.child.bind'){
            originFrom = 'bind_child';
            return originFrom;
        }else if(customRefFromCookie.type == 'pass.third.bind'){
            originFrom = 'bind_third';
            return originFrom;
        }else if(customRefFromCookie.type == 'pass.forget.forgetAccount'){
            originFrom = 'forget_account';
            return originFrom;
        }
    }
    var skipTo  = getCookie('skipTo');
    if(skipTo){
        //如果skipTo存在  优先获取
        originFrom = skipTo;
        return originFrom;
    }
    var nextpage  = getCookie('nextpage',true);
    if(nextpage){
        originFrom = nextpage;
        return originFrom;
    }
    var customConfig = getCookie('customConfig',true);
    if(customConfig){
        try {
            var customConfigObj = eval("(" + customConfig + ")");
            originFrom = customConfigObj.product_appkey;
            HIDDEN_MODULE = customConfigObj.hidden_module;
            return originFrom;
        } catch(err) {
        }
    }
    return '';
}


var appDeviceId;
var appMacId;
//获取移动端传过来的参数

function getNativeDeviceId(deviceId, macId) {
    //保存传过来的deviceId
    appDeviceId = deviceId;
    appMacId = macId;
    if (!!appDeviceId) {
        //从userAgent中判断请求是来自安卓还是ios
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        //判断登录是来自移动端
        if (isAndroid) {
            //调用获取deviceId接口
            //console.log("开始调用onGetDeviceIdSuccess接口");
            window.iflyapp.onGetDeviceIdSuccess(appDeviceId);
        } else if (isiOS && window.webkit.messageHandlers.onGetDeviceIdSuccess) {
            //调用获取deviceId接口
            //console.log("开始调用onGetDeviceIdSuccess接口");
            window.webkit.messageHandlers.onGetDeviceIdSuccess.postMessage(appDeviceId);
        }

        var extInfo = {};
        //console.log("进入组装extInfo方法，组装的deviceId为" + appDeviceId);
        extInfo.deviceId = appDeviceId;
        loginParam.extInfo = JSON.stringify(extInfo);
        loginParam.mac = appMacId;
        ssoLogin(loginParam, encodedInt, '',callbackApp);
    }

}
var loginParam, encodedInt, callbackApp;
function doSSOLogin(inputName, inputPass, encoded, isTopAction, callback, smsLoginCaptchaId) {
    loginParam = {};
    encodedInt = encoded;
    callbackApp = callback;
    var customConfig ={};
    customConfig.redirectUrl = SSO.loginRedirectUrl;
    //跳转到redirectUrl之前是否需要sso完成登录
    customConfig.needSsoLogin = "true";
    // customConfig.theme = "blue";
    if (encoded == 2) {
        loginParam.smsCaptchaId = smsLoginCaptchaId;
        loginParam.service = '';
        loginParam.phoneNum = inputName;
        loginParam.smsVerifyCode = inputPass;
        loginParam.loginType = SSO.loginType.LOGINBYVERIFYCODE;
        //跳转到redirectUrl之前是否需要sso完成登录
        customConfig.needSsoLogin = "true";
    }
    else {
        loginParam.account = inputName;
        loginParam.password = inputPass;
        loginParam.loginType = SSO.loginType.LOGINBYNORMAL;
    }
    loginParam.needBehaviorCheck = 'true';
    loginParam.behaviorCheckInPc = 'true';
    loginParam.customConfig = JSON.stringify(customConfig);
    //从userAgent中判断请求是来自安卓还是ios
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //判断登录是来自移动端
    if (isAndroid || isiOS) {
        //console.log("开始进入获取deviceId方法");
        loginParam.client = "app";
        if (isAndroid) {
            //调用获取deviceId接口
            if (window.iflyapp) {
                window.iflyapp.GetDeviceId();
            } else {
                ssoLogin(loginParam, encoded, isTopAction,callback);
            }

        } else if (isiOS) {
            if (window.webkit && window.webkit.messageHandlers.GetDeviceId) {
                //调用获取deviceId接口
                var param = {
                    "type": ""
                };
                window.webkit.messageHandlers.GetDeviceId.postMessage(JSON.stringify(param));
            } else {
                ssoLogin(loginParam, encoded,isTopAction, callback);
            }

        }
    } else {
        ssoLogin(loginParam, encoded, isTopAction,callback);
    }
}

function ssoLogin(loginParam, encoded,isTopAction, callback) {
    SSO.loginExt(loginParam, encoded, function (ssoResult) {

        //从userAgent中判断请求是来自安卓还是ios
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        //判断登录是来自移动端
        if (isAndroid || isiOS) {
            SSO.client = "app";
        }
        if (ssoResult.result == "success") {
            var needTicket = getCookie("needTicket");
            if (needTicket) {
                var ticket = ssoResult.data.st;
                var service = SSO.loginRedirectUrl;
                SSO.loginRedirectUrl = Url.addParams({"st": ticket, "service": service}, SSO.loginRedirectUrl);
            }else if(platform && !SSO.schoolId){
                //如果是平台统一登录非智校
                var currentUrl = window.location.search;
                var loginRedirectUrl;
                if (Url.queryString("nextpage", currentUrl) !== undefined || !!getCookie("nextpage")) {
                    loginRedirectUrl = Base64.decode(Url.queryString("nextpage", currentUrl) || getCookie("nextpage"));
                } else {
                    //如果没有nextpage 使用配置的跳转地址
                    loginRedirectUrl = SSO_LOGIN_REDIRECT_URL;
                }
                if (hiddenNextPageTicket) {
                    SSO.loginRedirectUrl = loginRedirectUrl;
                } else {
                    SSO.loginRedirectUrl = Url.addParams({"ticket": ssoResult.data.st}, loginRedirectUrl);
                }
            }
            if (SSO.client == "web") {
                isTopAction ? top.location.href = SSO.loginRedirectUrl:window.location.href = SSO.loginRedirectUrl;
            } else if(isAndroid){
                if (window.iflyapp) {
                    var tgt = ssoResult.data.tgt;
                    var username = ssoResult.params.username;
                    var param = {
                        'tgt': tgt,
                        'username': username
                    };
                    window.iflyapp.webLoginSuccess(JSON.stringify(param));
                }else{
                    isTopAction ? top.location.href = SSO.loginRedirectUrl:window.location.href = SSO.loginRedirectUrl;
                }
            }else if(isiOS){
                if (window.webkit && window.webkit.messageHandlers.webLoginSuccess) {
                    var tgt = ssoResult.data.tgt;
                    var username = ssoResult.params.username;
                    var param = {
                        'tgt': tgt,
                        'username': username
                    };
                    window.webkit.messageHandlers.webLoginSuccess.postMessage(JSON.stringify(param));
                }else{
                    isTopAction ? top.location.href = SSO.loginRedirectUrl:window.location.href = SSO.loginRedirectUrl;
                }
            }
        }else if(ssoResult.code === 2027 && ssoResult.behaviorCheckUrl){
            //支持自主解冻场景 弹窗提示跳转
            dialog({
                id: "dialog_forbidden_user",
                content: template("forbidden_template", {params:""}),
                modal: true,
                skin: 'eui-dialog'
            }).show();

            //确认回调
            $(document).on('click',"#user_forbidden_confirm",function(event){
                isTopAction ? top.location.href = ssoResult.behaviorCheckUrl:window.location.href = ssoResult.behaviorCheckUrl;
            });

            //叉掉弹窗回调
            $(document).on('click',"#close-icon-user-forbidden",function(event){
                dialog({
                    id:"dialog_forbidden_user"
                }).close();
            });

            //取消回调
            $(document).on('click',"#user_forbidden_cancel",function(event){
                dialog({
                    id:"dialog_forbidden_user"
                }).close();
            });
            ssoResult.data = "账号被冻结，请联系管理员!";
        }
        else {
            SSO.logout();
            if(isAndroid){
                if (window.iflyapp) {
                    window.iflyapp.webLoginFailed();
                }
            }else if(isiOS){
                if (window.webkit && window.webkit.messageHandlers.webLoginFailed) {
                    var tgt = ssoResult.data.tgt;
                    var username = ssoResult.params.username;
                    var param = {
                        'tgt': tgt,
                        'username': username
                    };
                    window.webkit.messageHandlers.webLoginFailed.postMessage(JSON.stringify(param));
                }
            }
        }
        callback && callback(ssoResult);
    });
}

function gotoLoginPage(isTopAction) {
    //从userAgent中判断请求是来自安卓还是ios
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //判断登录是来自移动端
    if (isAndroid || isiOS) {
        SSO.client = "app";
    }
  if (platform && !SSO.schoolId) {
        //如果是平台统一登录非智校
        var currentUrl = window.location.search;
        var loginRedirectUrl;
        if (Url.queryString("nextpage", currentUrl) !== undefined || !!getCookie("nextpage")) {
            loginRedirectUrl = Base64.decode(Url.queryString("nextpage", currentUrl) || getCookie("nextpage"));
        } else {
            //如果没有nextpage 使用配置的跳转地址
            loginRedirectUrl = SSO_LOGIN_REDIRECT_URL;
        }
        SSO.loginRedirectUrl = loginRedirectUrl;
    }
    if (SSO.client == "web") {
        isTopAction ? top.location.href = SSO.loginRedirectUrl : window.location.href = SSO.loginRedirectUrl;
    } else if (isAndroid) {
        if (window.iflyapp) {
            var tgt = ssoResult.data.tgt;
            var username = ssoResult.params.username;
            var param = {
                'tgt': tgt,
                'username': username
            };
            window.iflyapp.webLoginSuccess(JSON.stringify(param));
        } else {
            isTopAction ? top.location.href = SSO.loginRedirectUrl : window.location.href = SSO.loginRedirectUrl;
        }
    } else if (isiOS) {
        if (window.webkit && window.webkit.messageHandlers.webLoginSuccess) {
            var tgt = ssoResult.data.tgt;
            var username = ssoResult.params.username;
            var param = {
                'tgt': tgt,
                'username': username
            };
            window.webkit.messageHandlers.webLoginSuccess.postMessage(JSON.stringify(param));
        } else {
            isTopAction ? top.location.href = SSO.loginRedirectUrl : window.location.href = SSO.loginRedirectUrl;
        }
    }
}
//发送短信/邮箱验证码
function doSendMsgInWeb(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId) {
    var va;
    if (!!formObj)
        va = $(formObj).validator().data('validator');
    var to = $(inputTo).val();
    var picCode = $(inputPicCode)[0].value;
    $(btnCaptcha).removeAttr('onclick');
    var loadIndex;
    //如果传入了用户id，使用用户id传参
    var msgUserId = '';
    if (selectUserId){
        msgUserId = selectUserId;
    }
    $.ajax({
        type: "post",
        url: ctx + "/api/sendCaptchaMsg2",
        data: {
            o: to,
            t: type,
            p: picCode,
            r: referer,
            client: "WEB",
            actionFrom:"pd",
            cl: "web",
            co: captchaOutput,
            gt: genTime,
            ln: lotNumber,
            pt: passToken,
            cat: SSO.captchaType,
            ci: SSO.captchaId,
            uid: msgUserId
        },
        dataType: "json",
        cache: false,
        success: function (result) {
            var resultData = eval('(' + result.Data + ')');
            var data = {
                status: result.Code,
                msg: result.Msg,
            };
            if(inputTo == '#loginMobile'){
                //记录手机登录事件
                IFlyCollector .onEvent("1004", null, "login", "login", {'result':data,'mobile':to,'from':originFrom});
            }else if(inputTo == '#userMobile'){
                IFlyCollector .onEvent("1003", null, "forget", "forget", {'result':data,'from':originFrom});
            }
            if(inputTo == '#userEmail'){
                //忘记密码邮箱验证， 这里埋点埋加密的邮箱了，接口返回明文邮箱有安全风险
                IFlyCollector .onEvent("1007", null, "forget", "forget", {'email':resultData.encryptEmail,'from':originFrom,'result':data});
            }
            var func = 'javascript:sendMobileMsg()';
            if (type == "mobile"){
                func = 'javascript:sendMobileMsg()';
            } else if (type == "email"){
                func = 'javascript:sendEmailMsg()';
            }
            if (result.Code == 0) {
                IFlyCollector .bindUser(resultData.uid,null);
                if (type == "mobile"){
                    va && va.showMsg(inputCode, {
                        type: "tip",
                        msg: "短信发送中..."
                    });
                } else if (type == "email"){
                    va && va.showMsg(inputCode, {
                        type: "tip",
                        msg: "邮件发送中..."
                    });
                }
                if (result.Data) {
                    var resultJsonData = JSON.parse(result.Data);
                    SSO.captchaId = resultJsonData.captchaResult || SSO.captchaId;
                    if(inputTo == '#userMobile'){
                        //忘记密码手机验证 下一步就要验证手机号 需要把手机号取回来
                        $(inputTo).val(resultJsonData.encryptMobile);
                    }else if(inputTo == '#userEmail'){
                        //忘记密码邮箱验证
                        $(inputTo).val(resultJsonData.encryptEmail);
                    }
                }
                if(SSO.captchaType == SSO.picCaptchaType){
                    SSO.captchaCode = picCode;
                }
                timer(60, "##s后重试", "发送验证码", func, btnCaptcha);
            } else if(result.Code == -2 || result.Code == -1) {
                layer.msg(result.Msg);
                $('#picCodeM').val('');
                va && va.showMsg(inputCode, {
                    type: "tip",
                    msg: ""
                });
                $('#mobileCode').val('');
                $('#emailCode').val('');
                $(btnCaptcha).attr("onclick", func);
            } else if(result.Code === -13){
                layer.msg("验证失败，请稍后重试");
                if (type == "mobile"){
                    SSO.getCaptcha({
                        getCaptchaResultCB: getCaptchaResultCBForForgetMobile,
                        captchaSuccessResultCB: captchaSuccessResultCBForForgetMobile
                    });
                } else if (type == "email"){
                    SSO.getCaptcha({
                        getCaptchaResultCB: getCaptchaResultCBForForgetEmail,
                        captchaSuccessResultCB: captchaSuccessResultCBForForgetEmail
                    });
                }
            } else if(result.Code === -21){
                layer.msg('验证码有误，请重试');
                if (type == "mobile"){
                    SSO.getCaptcha({
                        getCaptchaResultCB: getCaptchaResultCBForForgetMobile,
                        captchaSuccessResultCB: captchaSuccessResultCBForForgetMobile
                    });
                } else if (type == "email"){
                    SSO.getCaptcha({
                        getCaptchaResultCB: getCaptchaResultCBForForgetEmail,
                        captchaSuccessResultCB: captchaSuccessResultCBForForgetEmail
                    });
                }
                $(btnCaptcha).attr("onclick", func);
            } else {
                //给标签添加onclick事件
                $(btnCaptcha).attr("onclick", func);
            }
        },
        error: function (xhr, error, thrown) {
            layer.close(loadIndex);
        }
    });
}

function extracted(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId) {
    var func = "javascript:doSendMsgInWeb(";
    func += !!inputTo ? "'" + inputTo + "'" : inputTo;
    func += !!inputPicCode ? ", '" + inputPicCode + "'" : ", " + inputPicCode;
    func += !!inputCode ? ", '" + inputCode + "'" : ", " + inputCode;
    func += !!btnCaptcha ? ", '" + btnCaptcha + "'" : ", " + btnCaptcha;
    func += !!formObj ? ", '" + formObj + "'" : ", " + formObj;
    func += !!type ? ", '" + type + "'" : ", " + type;
    func += ", '" + referer + "','" + selectUserId + "');";
    return func;
}

function preSendMsg(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId) {
    if(timeMark) return;
    doSendMsgInWeb(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId);
}

function sendMsgInWeb(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer, selectUserId) {
    $(inputCode).val("");
    if (!!formObj) {
        va = $(formObj).validator().data('validator');
        va && va.hideMsg(inputCode);
    }
    //有则校验收件人
    if (!!formObj && !!inputTo) {
        $(inputTo).isValid(function (v) {
            if (v) {
                if (SSO.captchaType === SSO.thirdCaptchaType){
                    SSO.verify();
                }else if(SSO.captchaType === SSO.picCaptchaType){
                    if (!!inputPicCode) {
                        $(inputPicCode).isValid(function (v) {
                            if (v) {
                                preSendMsg(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId);
                            }
                            if(!v){
                                changeCaptcha(inputPicCode,'#kaptcha2',type);
                            }
                        });
                    } else {
                        preSendMsg(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId);
                    }
                }
            }
        });
    }
    else {
        preSendMsg(inputTo, inputPicCode, inputCode, btnCaptcha, formObj, type, referer,selectUserId);
    }
}


function getCity(id) {
    // $("#City").val("");
    getArea(id, "City");
    getDistrict(0);
}

function getDistrict(id) {
    // $("#District").val("");
    getArea(id, "District");
}

function getArea(areaId, areaType) {
    var defaultHtml;
    switch (areaType) {
        case "Province":
            defaultHtml = "选择省";
            break;
        case "City":
            defaultHtml = "选择市";
            break;
        case "District":
            defaultHtml = "选择区/县";
            break;
        default:
            defaultHtml = "";
    }
    $("#" + areaType).find("option").remove();
    $("#" + areaType).append("<option value=''>" + defaultHtml + "</option>");
    $("#" + areaType).select2();
    if (!!areaId && areaId > 0) {
        $.ajax({
            "type": "post",
            "url": ctx + "/api/listArea",
            "data": {i: areaId, t: areaType},
            "dataType": "json",
            "cache": false,
            "success": function (result) {
                if (result.Code == 0) {
                    $.each(result.Data, function (i, v) {
                        if (v.id > 0 && !!v.areaName)
                            $("#" + areaType).append("<option  value='" + v.id + "'>" + v.areaName + "</option>");
                    });
                }
                /*else {
                 layer.msg(result.Msg, {icon: (result.Code == 0 ? 1 : 2), time: 2000});
                 }*/
            },
            "error": function (xhr, error, thrown) {
                if (xhr.status == 403)
                    location.href = xhr.getResponseHeader("Location");
            }
        });
    }
}

function getSchool(id) {
    if (!!!id) {
        var provinceId = $("#Province").val();
        var cityId = $("#City").val();
        var districtId = $("#District").val();
        if (!!districtId)
            id = districtId;
        else if (!!cityId)
            id = cityId;
        else if (!!provinceId)
            id = provinceId;
        else
            id = 1;
    }
    $("#School").find("option").remove();
    $("#School").append("<option value=''>请选择学校</option>");
    $("#School").select2();
    if (!!id && id > 0) {
        $.ajax({
            "type": "post",
            "url": ctx + "/api/listSchool",
            "data": {a: id},
            "dataType": "json",
            "cache": false,
            "success": function (result) {
                if (result.Code == 0) {
                    var schoolId = $("#schoolId").val();
                    $.each(result.Data, function (i, v) {
                        if (v.id > 0 && !!v.schoolName && v.id != schoolId)
                            $("#School").append("<option  value='" + v.id + "'>" + v.schoolName + "</option>");
                    });
                }
            },
            "error": function (xhr, error, thrown) {
                if (xhr.status == 403)
                    location.href = xhr.getResponseHeader("Location");
            }
        });
    }
}

function setCookie(name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = name + "=" + escape(value) +
        ((expiredays == null) ? "" : ";path=/;expires=" + exdate.toGMTString())
}

function getCookie(name, base64) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) {
        if (base64 === true)
            return Base64.decode(unescape(arr[2]));
        else
            return unescape(arr[2]);
    }
    else
        return null;
}

function delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

//倒计时开始（公共js），end:倒计时秒数，desc:描述，例如：获取验证码
var timeMark;
function timer(end, desc, defaultdesc, func, obj) {
    if(timeMark) return;
    timeMark=true;
    var i;
    for (i = 0; i <= end; i++) {
        setTimeout("update('" + i + "','" + end + "','" + desc + "','" + defaultdesc + "','" + func.replace(/'/g, '"') + "','" + obj + "')", i * 1000);
    }
}

function update(i, end, desc, defaultdesc, func, obj) {
    if (i == end) {
        $(obj).attr("disabled", false);
        $(obj).removeClass("gain-code-disabled");
        $(obj).addClass("gain-code");
        $(obj).attr("onclick", func);
        $(obj).html(defaultdesc);
        timeMark=false;
    } else {
        $(obj).attr("disabled", true);
        $(obj).removeClass("gain-code");
        $(obj).addClass("gain-code-disabled");
        $(obj).removeAttr('onclick');
        var printnr = end - 1 - i;
        $(obj).html(desc.replace("##", printnr));
    }
}
/***加载遮罩**/
function addLoad(){
    var box=document.createElement('div');
    var div=document.createElement('div');
    var bg=document.createElement('div');
    var picture=document.createElement('div');
    $(box).addClass('load-pop-box');
    $(div).addClass('load-pop-content').text('正在登录中');
    $(bg).addClass('load-pop-bg');
    $(picture).addClass('sequence');
    $(div).append(picture);
    $(box).append(div).append(bg);
    var dom=mDialog(box,null,true);
    // var d = dialog({
    //     modal : true,
    //     content:box,
    //     fixed:true
    // });
    // d.show();
    // $(box).parents('.ui-dialog').css({background:'none',border:'none'}).parent().prev().css({background:'none'});
    var setIntval=setInterval(function(){
        var positionLeft=parseInt($(picture).css('background-position').split(' ')[0])
        if(positionLeft==-180) positionLeft=0;
        $(picture).css({'background-position':positionLeft-60+'px center'})
    },300);
    return {hide:function(){clearInterval(setIntval);$(dom).remove()}}
}
var dia;
function loadingStart() {
    mark=true;
    dia=addLoad();
    $("#btnLogin").addClass("gslogin-sub-loading");
}

function loadingStop() {
    mark=false;
    dia.hide();
    $("#btnLogin").removeClass("gslogin-sub-loading");
}

function loginDone(inputName, inputPass) {
    var rememberPW = 0;
    if ($("#rememberPW").attr("checked") == "checked" || $("#rememberPW").hasClass("active"))
        rememberPW = 8;
    if (rememberPW > 0) {
        setCookie("u", Base64.encode(inputName), 7);
        setCookie("p", Base64.encode(inputPass), 7);
        setCookie("r", 8, 7);
    }
    else {
        delCookie("u");
        delCookie("p");
        delCookie("r");
    }
    // loadingStop();
}

function getAsterisk(n) {
    var asterisk = "";
    if (!!n && n > 0) {
        while (n--) {
            asterisk += '*';
        }
    }
    return asterisk;
}

function changeCaptcha(input, kaptcha, type) {
    $(input).val('');
    var src = "/newKaptcha.jpg?";
    src += "type=" + type;
    src += "&d=" + new Date().getTime();
    $(kaptcha).attr("src", ctx + src);
}

function selectTab(ele, cnum, n) {
    for (var i = 1; i <= n; i++) {
        var menu = $("#" + ele + i);
        var con = $("#" + ele + "_" + i);
        if (i == cnum) {
            menu.addClass("current");
            con.show();
        }
        else {
            menu.removeClass("current");
            con.hide();
        }
    }
}

function mDialog(d,n,f){
    var box=document.createElement('div');
    var bg=document.createElement('div');
    var contentBox=document.createElement('div');
    var content=document.createElement('div');
    var btn=document.createElement('div');
    var boxCss={width:'100%',height:'100%',position:'absolute',left:0,top:0};
    $(box).css(boxCss).css({'z-index':3});
    $(bg).css(boxCss).css({background:'#000',opacity:0.3});
    $(box).append(bg)
    if(f){
        $(box).append(d);
        $('body').append(box);
        return box
    }
    $(btn).css({width:'100%',height:'2rem','border-top':'1px solid #ddd','line-height':'2rem','text-align':'center','color':'#4d8cfd',position:'absolute',bottom:0,'font-size':'0.6rem'}).html('确定');
    $(contentBox).css({background:'#fff', 'text-align': 'center','border-radius': '0.5rem',width:'12rem',height:'5rem',position:'absolute',left:'50%',top:'50%','margin-left':'-6rem','margin-top':'-2.5rem','padding-top':'1.2rem'});
    $(content).html(d).css({overflow:'hidden','font-size':'0.65rem',color:'#c33','line-height': '0.8rem'});
    $(contentBox).append(content).append(btn);
    $(box).append(contentBox);
    $(btn).click(function(){
        $(box).remove();
    });
    $('body').append(box);
}

$(document).on('click','#btnCaptcha',function(){
    $(this).blur();
})

function postMessageToParent(msg){
    //console && console.log(msg);
    //判断登录是来自移动端
    if (window.webkit && window.webkit.messageHandlers.sucUnifiedLogin) {
        window.webkit.messageHandlers.sucUnifiedLogin.postMessage(JSON.stringify(msg));
    }else if (window.webkit && window.webkit.messageHandlers.postMessageToParent) {
        window.webkit.messageHandlers.postMessageToParent.postMessage(JSON.stringify(msg));
    }else if (window.sucUnifiedLogin && window.sucUnifiedLogin.postMessageToParent) {
        return window.sucUnifiedLogin.postMessageToParent(JSON.stringify(msg));
    }else if (window.SucInterface && window.SucInterface.postMessageToParent) {
        return window.SucInterface.postMessageToParent(JSON.stringify(msg));
    }else if (window.AppCommonInterface && window.AppCommonInterface.postMessageToParent) {
        return window.AppCommonInterface.postMessageToParent(JSON.stringify(msg));
    } else if (window.AppInterface && window.AppInterface.postMessageToParent) {
        return window.AppInterface.postMessageToParent(JSON.stringify(msg));
    }else {
        return window.parent.postMessage(JSON.stringify(msg), '*');
    }
}

/**
 * 全角转半角
 * 全角空格unicode编码为12288，半角空格为32
 其他字符半角(33-126)与全角(65281-65374)的unicode编码对应关系是：均相差65248
 * @param str
 * @returns {string}
 * @constructor
 */
function ToCDB(str) {
    var tmp = "";
    for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) == 12288) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
            continue;
        }
        if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
        } else {
            tmp += String.fromCharCode(str.charCodeAt(i));
        }
    }
    return tmp;
}